import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {confirmUser, logoutUser} from "../../../../../../providers/authService";
import {clearRegistrationState} from "../../../../../../actions/actions";
import store from "../../../../../../store";
import {getUserProfile} from "../../../../../../providers/userService";
import {ROUTE_PATHS} from "../../../../../../lib/constants";


const ConfirmComponent = (props) =>  {
    const {update} = props;
    const [userConfirmed, setUserConfirmed] = useState(false);
    const [confirmationError, setConfirmationError] = useState(null);
    const [isForbidden, setIsForbidden] = useState(false);
    const dispatch = useDispatch();
    const params = props.match.params;
    const path = "/confirmation";
    const user = useSelector(state => state.userReducer);

    useEffect(() => {
        const userConfirmation = () =>{
            dispatch(confirmUser(path, params)).then(()=> {
                dispatch(clearRegistrationState()).then(()=> {
                    setUserConfirmed(true);
                    store.dispatch(getUserProfile("/me")).then((resp)=> {
                        update(resp.action.payload.data.email);
                        props.history.push({
                            pathname: '/' + ROUTE_PATHS['checker'],
                            state: {from: "confirmation"}
                        });
                    });
                });
            }, (error)=> {
                if (error.response && error.response.status === 403) {
                    setConfirmationError('No, this will not work. What are you trying to do?');
                    setIsForbidden(true);
                } else {
                    setConfirmationError('Something went wrong.');
                }
            });
        };
        if (user.userLoggedIn) {
            store.dispatch(logoutUser("/signout")).then((resp)=> {
                userConfirmation();
            }, (error)=> {
                console.log(error.response);
            });
        } else {
            userConfirmation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {userConfirmed ?
                <div className="auth__confirm--container">
                    <h1>Welcome!</h1>
                </div>:null}
            {!userConfirmed && confirmationError ?
                <article className="auth__error--msg">
                    <p>{confirmationError}</p>
                    <p>
                        <Link to={'/register'}>
                            {!isForbidden ? 'Try again': 'Try again properly'}
                        </Link>
                    </p>
                    <p>
                        <b>{' or '}</b>
                    </p>
                    <p>
                        <a href="mailto:finaplo@paymentcomponents.com?subject=Unable to signup via email&body=">
                            {'Report your problem'}
                        </a>
                    </p>
                </article>
                :null}
        </React.Fragment>
    );
}

export const ConfirmPageContent = withRouter(ConfirmComponent);