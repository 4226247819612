import React from 'react';
import {ConfirmPageContent} from './ConfirmPageContent';

const ConfirmLazyHelper =(props)=>  {
    const {update} = props;
    return (
        <React.Fragment>
            <ConfirmPageContent update={update} />
        </React.Fragment>
    );
};

export default ConfirmLazyHelper;
