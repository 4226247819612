import React from "react";
import logo from "../../../assets/img/finaplo_logo.svg";
import {Link} from "react-router-dom";
import {sendGaEvent} from "../../lib/utils";
import {GA_EVENTS} from "../../lib/constants";


const MainLogo =()=> {

    return (
        <div className='logo'>
            <div className='branding'>
                <Link to={'/'}
                      title='FINaplo Financial Services'
                      className='branding__finaplo'
                      onClick={()=> {
                          sendGaEvent({category: 'header_internal_link',
                              action: GA_EVENTS.menu.logo.finaplo}
                          )}}>
                    <span>{'FINaplo'}</span>
                    <img src={logo} alt="FINaplo Financial Services logo" width="377" height="34" loading="eager"/>
                </Link>
                <a  href={process.env.PC_URL}
                    title='PaymentComponents'
                    className='branding__paymentcomponents'
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={()=> {
                        sendGaEvent({category: 'header_external_link',
                            action: GA_EVENTS.menu.logo.pc14}
                        )}}>
                    BY PAYMENT COMPONENTS
                </a>

            </div>
        </div>
    );
}

export default MainLogo;


