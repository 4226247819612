import React, {useEffect} from 'react';
import {Link, withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import Banner from "../../../uiElements/Banner";
import {GA_EVENTS, PLAN_MAP, PURCHASE_INQUIRY_MAIL_BODY, ROUTE_PATHS} from "../../../../lib/constants";
import MainLogo from "../../../uiElements/MainLogo";
import {sendGaEvent} from "../../../../lib/utils";


const PaymentPageContentComponent = (props) =>  {
    const {history} = props;

    const user = useSelector(state => state.userReducer);

    const arr = [0, 1, 2, 3, 4, 5];

    useEffect(()=> {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if (!user.profile.fetching && user.userPermission === 'unlimitedActive') {
            history.push('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.profile.fetching, user.userPermission]);


    return (
        <React.Fragment>
            <Link to={'/' + ROUTE_PATHS['pricing']}
                  className='button__back'>
                <i className="bg-sprites bg-arrow_left"/>
                <span>Back</span>
            </Link>
                <div className="purchase-req">
                    <div className="purchase-req__col purchase-req__col--left-wrapper">
                        <div className="purchase-req__left">
                            <MainLogo />
                            <Banner />
                        </div>
                    </div>
                    <div className="purchase-req__col purchase-req__col--right-wrapper">
                        <div className="purchase-req__right">
                            <div className="purchase-req__title">
                                <h3>Purchase Request</h3>
                            </div>
                            <div className="purchase-req__info">
                                <h4>{"UNLIMITED PLAN"}</h4>
                                {arr.map((par)=> {
                                        return <p key={par + "-about-plan"}>{PLAN_MAP['about' + par.toString()]}</p>
                                    })
                                }
                            </div>
                            <div className="purchase-req__mail--btns">
                                <article className="inquiry">
                                    <a className="btn__generic"
                                        href={`mailto:sales@paymentcomponents.com?subject=Purchase Request FINaplo Financial Services Online&body=` + PURCHASE_INQUIRY_MAIL_BODY}
                                        onClick={()=> {
                                           sendGaEvent({category: 'button_mailto', action: GA_EVENTS.payment}
                                           )}}>
                                        {'Purchase Inquiry'}
                                    </a>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
}

export const PaymentPageContent =  withRouter(PaymentPageContentComponent);
