import React, {useEffect, useState} from "react";
import {GA_EVENTS, HOMEPAGE_CONTENT, ROUTE_PATHS} from "../../../lib/constants";
import {Link} from "react-router-dom";
import {getBrowser, sendGaEvent} from "../../../lib/utils";

const ApisInfo =()=>  {
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        let usrAgent = getBrowser(navigator.userAgent);
        if (usrAgent.type) {
            setIsSafari(usrAgent.type === 'safari');
        }
    }, []);

    return (
        <div className='info__item info__item--apis'>
            <article>
                <h4>
                    {HOMEPAGE_CONTENT.illustration.apis.title}
                </h4>
                <p className='info__text--caption'>
                    {HOMEPAGE_CONTENT.illustration.apis.textCaption}
                </p>
                <div className='illustration illustration__apis'>
                    <div className='illustration__content illustration__content--apis'>
                        <div className='illustration__background illustration__background--circle-intersection'>
                            <div className='illustration__circle-intersection illustration__circle-intersection--light'>
                            </div>
                            <div className='illustration__circle-intersection illustration__circle-intersection--dark'>
                            </div>
                        </div>
                        <div className='illustration__animation'>
                            <div className='bg-hp bg-code_api illustration__animation--code illustration__animation--apis-code'>
                            </div>
                            <div className={'illustration__animation--message illustration__animation--apis-message illustration__animation--apis-message__a' +
                                (isSafari ? ' __safari': '')
                            }>
                            </div>
                            <div className={'illustration__animation--message illustration__animation--apis-message illustration__animation--apis-message__b' +
                                (isSafari ? ' __safari': '')
                            }>
                            </div>
                            <div className={'illustration__animation--message illustration__animation--apis-message illustration__animation--apis-message__c' +
                                (isSafari ? ' __safari': '')
                            }>
                            </div>
                            <div className='bg-hp bg-network-from-to_api illustration__animation--apis-input'>
                            </div>
                            <div className='bg-hp bg-cog_api illustration__animation--api-container illustration__animation--apis-api-container'>
                            </div>
                            <div className='bg-hp bg-api-text_api illustration__animation--api-text illustration__animation--apis-api-text'>
                            </div>
                            <div className='bg-hp bg-network-from-to_api illustration__animation--apis-output'>
                            </div>
                            <div className='bg-hp bg-cube_api illustration__animation--sdk-container illustration__animation--apis-sdk-container'>
                            </div>
                            <div className='bg-hp bg-sdk-text_api illustration__animation--sdk-text illustration__animation--apis-sdk-text'>
                            </div>
                            <div className='illustration__animation--apis__sdk-fill'>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='illustration__caption illustration__caption--apis'>
                    {HOMEPAGE_CONTENT.illustration.apis.animationCaption}
                </div>
                {HOMEPAGE_CONTENT.illustration.apis.text.map((paragraph, idx)=> {
                    return <p key={'illustration-paragraph-apis-' + idx}
                                      className={'info__text--paragraph info__text--paragraph-apis_' + (idx + 1)}>
                                {paragraph}
                            </p>
                })
                }
            </article>
            <div className='illustration__button--wrapper illustration__button--wrapper__apis'>
                <span>{HOMEPAGE_CONTENT.illustration.apis.buttonTitle}</span>
                <div className='illustration__button--container illustration__button--container__apis'>
                    <Link to={'/' + ROUTE_PATHS['checker']}
                          className="btn__generic--blue-hover"
                          onClick={()=> {
                              sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.homepage.anim.online}
                              )}}>
                        {HOMEPAGE_CONTENT.illustration.apis.buttonOnline}
                    </Link>
                    <Link to={'/' + ROUTE_PATHS['api']}
                          className="btn__generic--blue-hover"
                          onClick={()=> {
                              sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.homepage.anim.apis}
                              )}}>
                        {HOMEPAGE_CONTENT.illustration.apis.buttonApi}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ApisInfo;