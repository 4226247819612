import React from 'react';


function Banner(props) {
    const checkMarks = () => {
        return  <div className="checkmark">
            <div className="checkmark_stem"/>
            <div className="checkmark_kick"/>
        </div>
    };

    return (
        <div className='banner'>
            <div className='banner__checkmarks'>
                {checkMarks()}
                {checkMarks()}
            </div>
            <div className='banner__text'>
                {props.blue?
                    <React.Fragment>
                        More than<br/>
                        <span>300,000 messages</span><br/>
                        checked annually<br/>
                        through our software
                    </React.Fragment>:
                    <React.Fragment>
                        <span>{"+300,000"}</span><br/>
                        messages checked annually through our software
                    </React.Fragment>}
            </div>
        </div>
    );
}

export default Banner;
