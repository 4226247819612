import React, {useEffect, useState} from "react";
import {GA_EVENTS, HOMEPAGE_CONTENT, ROUTE_PATHS} from "../../../lib/constants";
import {Link} from "react-router-dom";
import {getBrowser, sendGaEvent} from "../../../lib/utils";

const FinMessagesInfo =()=>  {
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        let usrAgent = getBrowser(navigator.userAgent);
        if (usrAgent.type) {
            setIsSafari(usrAgent.type === 'safari');
        }
    }, []);

    return (
        <div className='info__item info__item--fin-messages'>
            <article>
                <h4>
                    {HOMEPAGE_CONTENT.illustration.financialMessages.title}
                </h4>
                <p className='info__text--caption'>
                    {HOMEPAGE_CONTENT.illustration.financialMessages.textCaption}
                </p>
                <div className='illustration illustration__fin-messages'>
                    <div className='illustration__content illustration__content--fin-messages'>
                        <div className='illustration__background illustration__background--circle-subset'>
                            <div className='illustration__circle-subset illustration__circle-subset--light'>
                            </div>
                            <div className='illustration__circle-subset illustration__circle-subset--dark'>
                            </div>
                        </div>
                        <div className='illustration__animation'>
                            <div className='bg-hp bg-code_sdk illustration__animation--code illustration__animation--fin-messages-code'>
                            </div>
                            <div className={'illustration__animation--message illustration__animation--fin-messages-message illustration__animation--fin-messages-message__a' +
                                (isSafari ? ' __safari': '')
                            }>
                            </div>
                            <div className={'illustration__animation--message illustration__animation--fin-messages-message illustration__animation--fin-messages-message__b' +
                                (isSafari ? ' __safari': '')
                            }>
                            </div>
                            <div className={'illustration__animation--message illustration__animation--fin-messages-message illustration__animation--fin-messages-message__c' +
                                (isSafari ? ' __safari': '')
                            }>
                            </div>
                            <div className='bg-hp bg-network-from_sdk illustration__animation--fin-messages-input'>
                            </div>
                            <div className='bg-hp bg-cog_sdk illustration__animation--api-container illustration__animation--fin-messages-api-container'>
                            </div>
                            <div className='bg-hp bg-api-text_sdk illustration__animation--api-text illustration__animation--fin-messages-api-text'>
                            </div>
                            <div className='bg-hp bg-network-to_sdk illustration__animation--fin-messages-output'>
                            </div>
                            <div className='bg-hp bg-cube_sdk illustration__animation--sdk-container illustration__animation--fin-messages-sdk-container'>
                            </div>
                            <div className='bg-hp bg-sdk-text_sdk illustration__animation--sdk-text illustration__animation--fin-messages-sdk-text'>
                            </div>
                            <div className='illustration__animation--fin-messages__sdk-fill'>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='illustration__caption illustration__caption--fin-messages'>
                    {HOMEPAGE_CONTENT.illustration.financialMessages.animationCaption}
                </div>
                {HOMEPAGE_CONTENT.illustration.financialMessages.text.map((paragraph, idx)=> {
                    return <p key={'illustration-paragraph-fin-messages-' + idx}
                              className={'info__text--paragraph info__text--paragraph-fin-messages_' + (idx + 1)}>
                        {paragraph}
                    </p>
                })
                }
            </article>
            <div className='illustration__button--wrapper illustration__button--wrapper__fin-messages'>
                <span>{HOMEPAGE_CONTENT.illustration.financialMessages.buttonTitle}</span>
                <div className='illustration__button--container illustration__button--container__fin-messages'>
                    <Link to={'/' + ROUTE_PATHS['financialMessages']}
                          className="btn__generic--blue-hover"
                          onClick={()=> {
                              sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.homepage.anim.finMsgs}
                              )}}>
                        {HOMEPAGE_CONTENT.illustration.financialMessages.button}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FinMessagesInfo;