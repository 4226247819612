import React from 'react';
import {PaymentPageContent} from './PaymentPageContent';

const PaymentLazyHelper =(props)=>  {
    return (
        <React.Fragment>
            <PaymentPageContent />
        </React.Fragment>
    );
};

export default PaymentLazyHelper;
