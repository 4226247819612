import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {confirmUserLinkedin, logoutUser} from "../../../../../../providers/authService";
import store from "../../../../../../store";
import {getUserProfile} from "../../../../../../providers/userService";
import {ROUTE_PATHS} from "../../../../../../lib/constants";


const ConfirmLinkedinComponent = (props) =>  {
    const {update, location} = props;
    const [params, setParams] = useState(null);
    const [userConfirmed, setUserConfirmed] = useState(false);
    const [confirmationError, setConfirmationError] = useState(null);
    const dispatch = useDispatch();
    const path = "/oauth";
    const user = useSelector(state => state.userReducer);

    useEffect(() => {
        if (location.search) {
            const search = new URLSearchParams(location.search.toString().substring(1));
            const code = search.get('code');
            const state = search.get('state');
            const searchParams = {code: code, state:state}
            setParams(searchParams);
        } else {
            if (props.history) {
                props.history.push({
                    pathname: '/' + ROUTE_PATHS['checker'],
                    state: {from: "linkedin/oauth"}
                });
            }
        }
    }, [location, props.history]);

    useEffect(() => {
        if (params) {
            const userLinkedinConfirmation = () => {
                dispatch(confirmUserLinkedin(path, params)).then(() => {
                    setUserConfirmed(true);
                    store.dispatch(getUserProfile("/me")).then((resp) => {
                        update(resp.action.payload.data.email);
                        props.history.push({
                            pathname: '/' + ROUTE_PATHS['checker'],
                            state: {from: "oauth"}
                        });
                    });
                }, () => {
                    setConfirmationError('Something went wrong.');
                });
            };
            if (user.userLoggedIn) {
                store.dispatch(logoutUser("/signout")).then((resp) => {
                    userLinkedinConfirmation();
                }, (error) => {
                    console.log(error.response);
                });
            } else {
                userLinkedinConfirmation();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <React.Fragment>
            {userConfirmed ?
                <div className="auth__confirm--container">
                    <h1>Welcome!</h1>
                </div>:null}
            {!userConfirmed && confirmationError ?
                <article className="auth__error--msg">
                    <p>{confirmationError}</p>
                    <p>
                        <Link to={'/register'}>Try again</Link>
                    </p>
                    <p>
                        <b>{' or '}</b>
                    </p>
                    <p>
                        <a href="mailto:finaplo@paymentcomponents.com?subject=Unable to signup via LinkedIn&body=">
                            {'Tell us your problem'}
                        </a>
                    </p>
                </article>
                :null}
        </React.Fragment>
    );
};

export const ConfirmLinkedinPageContent = withRouter(ConfirmLinkedinComponent);