import React from 'react';
import {ConfirmLinkedinPageContent} from './ConfirmLinkedinPageContent';

const ConfirmLinkedinLazyHelper =(props)=>  {
    const {update} = props;

    return (
        <React.Fragment>
            <ConfirmLinkedinPageContent update={update} />
        </React.Fragment>
    );
};

export default ConfirmLinkedinLazyHelper;
